import { CustomerPageWrapper } from 'containers/CustomerPageWrapper';
import {
  CurrentUser,
  GearFilters,
  GearResponse,
  GearListFilters,
  MenuResponse,
  MenuItem,
  SimpleItem,
  DefaultOgDataProps,
} from 'types';
import { GearBlock } from './GearBlock';
import { convertSlugToCaption } from 'utils/formatters';
import { getCommonOgData, getPageH1Title } from 'utils/seoFormatters';

export type GearPageProps = {
  activities: Array<MenuItem & SimpleItem>;
  appliedFilters: GearListFilters;
  filters: GearFilters;
  initialGear: GearResponse;
  menuData: MenuResponse;
  ogDataProps?: DefaultOgDataProps;
  user?: CurrentUser;
};

const getHeadTitle = (appliedFilters) => {
  let headTitle = 'Search gear';

  if (appliedFilters.q) {
    headTitle = `${appliedFilters.q} - search results`;
  } else {
    let marketFilter = appliedFilters.markets
      ? (Array.isArray(appliedFilters.markets) ? appliedFilters.markets[0] : appliedFilters.markets)
      : null;
    if (marketFilter) {
      marketFilter = convertSlugToCaption(marketFilter, '-');
    }

    const categoryFilter = appliedFilters.category
      ? convertSlugToCaption(appliedFilters.category, '-')
      : null;

    if (marketFilter && categoryFilter) {
      headTitle = `${marketFilter} - ${categoryFilter} items`;
    } else if (marketFilter) {
      headTitle = `${marketFilter} items`;
    } else if (categoryFilter) {
      headTitle = `${categoryFilter} items`;
    }
  }

  return `${headTitle} | SENDY`;
}

export const GearPage = ({
  activities,
  appliedFilters,
  initialGear,
  filters,
  menuData,
  ogDataProps,
  user,
}: GearPageProps) => {
  const headTitle = getHeadTitle(appliedFilters);
  const ogData = getCommonOgData({ ...(ogDataProps), title: headTitle });

  return (
    <CustomerPageWrapper
      menuData={menuData}
      user={user}
      headTitle={headTitle}
      ogData={ogData}
      hiddenComponentProps={{ title: getPageH1Title('searchGear') }}
      appliedFilters={appliedFilters}
    >
      <>
        <GearBlock initialGear={initialGear} activities={activities} filters={filters} appliedFilters={appliedFilters} user={user} />
      </>
    </CustomerPageWrapper>
  );
};
