import { useEffect } from 'react';
// @ts-ignore
import { renderToStaticMarkup } from 'react-dom/server';
import { createHtmlMarker } from './HtmlMarker';
import { GearMapBucket } from 'types';

import styles from './GearsMap.module.scss';

export type BucketMarkerProps = {
  bucket: GearMapBucket;
  map: any;
  onSelect?: () => void;
};

export const BucketMarker = ({ bucket, map, onSelect }: BucketMarkerProps) => {
  useEffect(() => {
    const content = renderToStaticMarkup(
      <div className={styles.bucketMarker}>
        {bucket.count}
      </div>
    );
    const marker = createHtmlMarker(map, bucket.center, content);
    marker.addListener('click', () => {
      onSelect && onSelect();
    });

    return () => {
      marker.remove();
    };
  }, [bucket.count]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};