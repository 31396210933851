export type Position = {
  lat: number;
  lng: number;
};

export type MarkerPosition = 'center' | 'bottom-left' | 'bottom-center' | 'none';

export type Offset = {
  x?: number;
  y?: number;
};

export const createHtmlMarker = (
  map: any,
  position: Position,
  content: string,
  markerPosition: MarkerPosition = 'center',
  offset: Offset = {}
) => {
  // @ts-ignore
  class HtmlMarker extends google.maps.OverlayView {
    position: Position;
    content: string;
    div?: HTMLDivElement|null;
    markerPosition: MarkerPosition;
    offset: Offset;
    listeners: any = {
      click: [],
    };

    constructor(
      map: any,
      position: Position,
      content: string,
      markerPosition: MarkerPosition = 'center',
      offset: Offset = {}
    ) {
      super();
      this.position = position;
      this.content = content;
      this.markerPosition = markerPosition;
      this.offset = offset;
      // @ts-ignore
      this.setMap(map);
    }

    createDiv() {
      this.div = document.createElement('div');
      this.div.style.position = 'absolute';
      this.div.innerHTML = this.content;

      this.div.addEventListener('click', event => {
        this.listeners.click.forEach((listener: any) => listener(event));
      });
    }

    appendDivToOverlay() {
      // @ts-ignore
      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(this.div);
    }

    positionDiv() {
      // @ts-ignore
      const point = this.getProjection().fromLatLngToDivPixel(this.position);
      if (point && this.div) {
        let x = +point.x;
        let y = +point.y;
        const width = this.div.clientWidth;
        const height = this.div.clientHeight;

        if (this.markerPosition === 'center') {
          x -= width / 2;
          y -= height / 2;
        } else if (this.markerPosition === 'bottom-left') {
          y -= height;
        } else if (this.markerPosition === 'bottom-center') {
          x -= width / 2;
        }

        if (this.offset.x) {
          x += this.offset.x;
        }
        if (this.offset.y) {
          y += this.offset.y;
        }

        this.div.style.left = `${x}px`;
        this.div.style.top = `${y}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    reDraw() {
      if (this.div) {
        this.div.innerHTML = this.content;
      }
    }

    remove() {
      // @ts-ignore
      this.setMap(null);
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode?.removeChild(this.div);
        this.div = null;
      }
    }

    getPosition() {
      return this.position;
    }

    getDraggable() {
      return false;
    }

    getDimensions() {
      return {
        width: this.div?.clientWidth || 0,
        height: this.div?.clientHeight || 0,
      }
    }

    setContent(content: string) {
      this.content = content;
      this.reDraw();
    }

    addListener(event: string, listener: any) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }

      this.listeners[event].push(listener);
    }
  }

  return new HtmlMarker(map, position, content, markerPosition, offset);
};
