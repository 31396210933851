import { BrandsList } from '../Brands';
import { CommonCustomerItem } from 'types';

import cx from 'classnames';
import styles from './Activities.module.scss';

export type ActivitiesProps = {
  activeItem?: string;
  activities: CommonCustomerItem[];
  className?: string;
  customThumbnailClass?: string;
  onClick?: (slug: string) => void;
};

export const Activities = ({ activeItem, activities, className, customThumbnailClass, onClick }: ActivitiesProps) => {
  return (
    <div className={cx(styles.section, className)}>
      <div className={styles.sectionContent}>
        <BrandsList
          activeItem={activeItem}
          className={styles.feedActivities}
          customThumbnailClass={customThumbnailClass}
          leadTo="activities"
          onClick={onClick}
          brands={activities}
          type="inlineFeed"
          withOpacity={false}
          withTitle={true}
        />
      </div>
    </div>
  );
};
