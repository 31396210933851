import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
// @ts-ignore
import { renderToStaticMarkup } from 'react-dom/server';
import { createHtmlMarker } from './HtmlMarker';
import { ProfileGear } from 'types';
import { USER_COVER_PLACEHOLDER_LINK } from 'consts';

import styles from './GearsMap.module.scss';
import cx from 'classnames';

export type GearMarkerProps = {
  gear: ProfileGear;
  map: any;
  selected?: boolean;
  onSelect?: () => void;
};

export const GearMarker = ({ gear, map, selected, onSelect }: GearMarkerProps) => {
  const markerRef = useRef<any>(null);
  const infoWindowRef = useRef<any>(null);
  const router = useRouter();

  useEffect(() => {
    const marker = renderMarker();
    markerRef.current = marker;
    marker.addListener('click', () => {
      onSelect && onSelect();
    });

    return () => {
      markerRef.current.remove();
      if (infoWindowRef.current) {
        onSelect && onSelect();
        infoWindowRef.current.remove();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selected && !infoWindowRef.current && markerRef.current) {
      const infoWindowContent = renderToStaticMarkup(
        <div className={styles.gearInfoWindow}>
          <div className={styles.gearInfoWindowImage} style={{backgroundImage: `url(${gear.cover_image || USER_COVER_PLACEHOLDER_LINK})`}}>
            <div className={cx(styles.gearInfoWindowClose, 'map-close__button')}>
              <img src="/icons/math/XWhite.svg" alt="" className="map-close__button"/>
            </div>
          </div>
          <div className={styles.gearInfoWindowContent}>
            <div className={styles.gearInfoWindowTitleRow}>
              <div className={styles.gearInfoWindowTitle}>
                {gear.title}
              </div>
              <div className={styles.gearInfoWindowFavorites}>
                <div>{gear.users_favorites_count}</div>
                <img src={gear.in_my_favorites ? '/icons/StarBlue.svg' : '/icons/weather/StarDark.svg'} alt=""/>
              </div>
            </div>
            <div className={styles.gearInfoWindowPriceRow}>
              <span>${gear.price} {gear.currency}</span>
              {gear.type === 'rent' && <span className={styles.gearInfoWindowPriceUnit}>/day</span>}
            </div>
          </div>
        </div>
      );
      const info: any = createHtmlMarker(
        map,
        gear.location,
        infoWindowContent,
        'bottom-center',
        {y: markerRef.current.getDimensions().height / 2 + 8}
      );
      infoWindowRef.current = info;
      renderMarker(markerRef.current, true);

      info.addListener('click', (e: any) => {
        if (e.target.classList.contains('map-close__button')) {
          infoWindowRef.current.remove();
          infoWindowRef.current = null;
          onSelect && onSelect();
          renderMarker(markerRef.current);
        } else {
          handleClickInfoWindow();
        }
      });
    } else if (!selected && infoWindowRef.current) {
      infoWindowRef.current.remove();
      infoWindowRef.current = null;
      renderMarker(markerRef.current);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderMarker = (marker?: any, active: boolean = false) => {
    const iconType = active ? 'White' : 'Dark';

    const content = renderToStaticMarkup(
      <div className={cx(styles.gearMarker, {
        [styles.gearMarkerActive]: active,
      })}>
        <img src={`/icons/${gear.type === 'sell' ? 'commerce/Money' + iconType : 'time/CalendarBlank' + iconType }.svg`} alt=""/>
        <span>${gear.price}</span>
      </div>
    );

    if (!marker) {
      marker = createHtmlMarker(map, gear.location, content);
    } else {
      marker.setContent(content);
    }

    return marker;
  }

  const handleClickInfoWindow = () => {
    router.push(`/gear/${gear.slug}`).then();
  }

  return null;
};
